import React from "react"

// Components
import { Product } from '../components'
import Layout from "../components/layout"
import { Pagination } from '../components/pagination'

const ProductList = ({ data }) => (
  <div 
    className="row product-main" 
    style = {{ marginTop: '10vh'}}
  >
    { 
      data.map( ( product, index ) => (
        <Product 
          key = { index } 
          image = { product.images[ 0 ]?.originalSrc } 
          title = { product.title } 
          handle = { product.handle } 
          compareAtPrice = { 0 }
          price = { 0 }
        /> 
      )) 
    }
  </div>
)

const CollectionPage = ({ path, pageContext }) => {

  const description = path === '/web-only-specials' ? 'Promotions and offers' : null

  return (
    <Layout
      title = { pageContext.collection }
      index
      description = { description }
    >
      <div  
        className = "container" 
      >
        <div className="text-center mt-5">
          <h1 style = {{ margin:0 }}>{ pageContext.collection }</h1>
          {
            path === '/web-only-specials' &&  (
              <>
                <h2 style = {{ color: 'white', margin: 0 }}>Newest promotion</h2>
              </>
            )
          }
        </div>
        <ProductList  data = { pageContext.product } />
        <Pagination 
          path = { `/collection/${ pageContext.handle }` } 
          currentPage = { pageContext.currentPage } 
          numPages = { pageContext.numPages } 
        />
      </div>
    </Layout>
  )
}

export default CollectionPage