import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Component
import ForwardIcon from '@material-ui/icons/Forward'
import { Flag } from './'

const PaginationWrapper = styled.div`
    width: 100%;
    min-height : 8vh;
    display: flex;
    justify-content: space-around;
    align-items : center;
    flex-direction: row;
`

const Component = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items : center;
`

const FlagWrapper = styled.div`
    width : 20vw;
    max-width : 100px;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`

export const Pagination = props => {

    const handlePageChange = e => {
        const page = parseInt( e.target.value ) + 1
        var url = `${ props.path }${ page > 1 ? '/page' + page : '' }`
        window.location.href = url
    }

    const { currentPage, numPages } = props
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? `${ props.path }` : `${ props.path }/page${ currentPage - 1 }`
    const nextPage = `${ props.path }/page${ currentPage + 1 }`

    return(
        <>
            <PaginationWrapper>
                <FlagWrapper>
                    <Flag country = { 'ca' }/>
                    <Flag country = { 'us' }/>
                </FlagWrapper>
            </PaginationWrapper>
            <PaginationWrapper>
                <Component>
                    {
                        !isFirst && (
                            <Link to = { prevPage } rel = "prev" >
                                <ForwardIcon style = {{ transform : 'rotate(180deg)' , color : '#009688' }} />
                            </Link>
                        )
                    }
                </Component>
                <Component>
                    <select 
                        style = {{
                            height: '5vh',
                            width: '15vh',
                            minWidth : '150px',
                            paddingLeft : '1vw',
                            border: 0,
                            cursor: 'pointer',
                            boxShadow : '0 0 3px grey',
                        }}
                        onChange = { e => handlePageChange( e ) }
                    >
                        { 
                            Array.from({ length: props.numPages }).map( ( x, index ) => {
                                return <option value = { index } key = { index } selected = { props.currentPage - 1  === index } disabled = { props.currentPage - 1  === index }> Page { index + 1 } </option>
                            })
                        }
                    </select>
                </Component>
                <Component>
                    {
                        !isLast && (
                            <Link to = { nextPage } rel = "next" >
                                <ForwardIcon style = {{ transform : 'rotate(0deg)' , color : '#009688' }} />
                            </Link>
                        )
                    }
                </Component>
            </PaginationWrapper>
            <PaginationWrapper>
                
            </PaginationWrapper>
        </>
    )
}